import noImagePlaceholder from '../../../../assets/common/no-image-placeholder.png';
import poiActive from '../../../../assets/vauxhall/icon/poiActive.svg';
import poiInActive from '../../../../assets/vauxhall/icon/poiInActive.svg';
import onMap from '../../../../assets/vauxhall/icon/onMap.svg';
import onMapGrey from '../../../../assets/vauxhall/icon/onMapGrey.svg';
import phone from '../../../../assets/vauxhall/icon/phone.svg';
import phoneGrey from '../../../../assets/vauxhall/icon/phoneGrey.svg';
import generalHours from '../../../../assets/vauxhall/icon/generalHours.svg';
import vxPartsStoreIcon from '../../../../assets/vauxhall/icon/services/parts.svg';
import vxPassengerCarsSalesIcon from '../../../../assets/vauxhall/icon/services/passengercarsales.svg';
import vxPassengerCarsServiceIcon from '../../../../assets/vauxhall/icon/services/service.svg';
import vxUsedCarsIcon from '../../../../assets/vauxhall/icon/services/usedcars.svg';

export const vxAssets = Object.freeze({
  noImagePlaceholder,
  poiActive,
  poiInActive,
  onMap,
  onMapGrey,
  phone,
  phoneGrey,
  generalHours,
  vxPartsStoreIcon,
  vxPassengerCarsSalesIcon,
  vxPassengerCarsServiceIcon,
  vxUsedCarsIcon,
});
