import nextArrow from '../../../../assets/fiat/icon/nextArrow.svg';
import noImagePlaceholder from '../../../../assets/common/no-image-placeholder.png';
import checkMark from '../../../../assets/fiat/icon/checkMark.svg';
import checkboxTick from '../../../../assets/fiat/icon/checkboxTick.svg';
import closeIcon from '../../../../assets/fiat/icon/closeIcon.svg';
import filterOpener from '../../../../assets/ds/icon/filterOpener.svg';
import prevArrow from '../../../../assets/fiat/icon/prevArrow.svg';
import accordionChevron from '../../../../assets/fiat/icon/accordion.svg';
import docExtension from '../../../../assets/fiat/icon/extension.svg';

export const fiatAssets = Object.freeze({
  nextArrow,
  prevArrow,
  noImagePlaceholder,
  checkMark,
  checkboxTick,
  closeIcon,
  filterOpener,
  accordionChevron,
  docExtension,
});
